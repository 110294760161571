import createDataContext from "./createContext";
import { BaseService } from "../api";
import { BaseRedirect } from "../api";
import { sendNotification } from "../hooks/useNotification";
import { NotificationContent } from "../components";
import { Route } from "react-router-dom";

const END_LOGIN = "end_login";
const LOGOUT = "logout";
const IS_VALID_ADD = "is_valid_add";

const INITIAL_STATE = {
  user: null,
  isValidAdd:null,
  configAll:{}
};


const authReducer = (state, action) => {
  switch (action.type) {
   
    case END_LOGIN: {
      const { user, configAll } = action.payload;
      return { ...state, user, configAll};
    }
    case LOGOUT: {
      return { ...INITIAL_STATE };
    }
    case IS_VALID_ADD: {
      return { ...state, isValidAdd: action.payload };
    }
  
    default: {
      return state;
    }
  }
};

const loginforredirect = (dispatch) => async (loginForm, navigate, server1, server2,configUser, getNationsLists) => {
  const response1 = await BaseRedirect.post(`${server1}/login`, {
    jwtusername: loginForm.username,
    jwtpassword: loginForm.password
  });

  const response2 = await BaseRedirect.post(`${server2}/login`, {
    jwtusername: loginForm.username,
    jwtpassword: loginForm.password
  });



  if (response1.data){
    let splitPrm = server1.split("/");
    let url = `${splitPrm[0]}//${splitPrm[2]}/redirectedlogin/${btoa(loginForm.username)}/${btoa(loginForm.password)}`;
    //let url = `${splitPrm[0]}//localhost:3000/redirectedlogin/${btoa(loginForm.username)}/${btoa(loginForm.password)}`;

    window.location.href = url;

  }
  else if (response2.data) {
    let splitPrm = server2.split("/");
    let url = `${splitPrm[0]}//${splitPrm[2]}/redirectedlogin/${btoa(loginForm.username)}/${btoa(loginForm.password)}`;

    window.location.href = url;
  }
  else{
      sendNotification(
         <NotificationContent service={"Errore!"} />,
         "Credenziali non valide",
         "error",
        10
      );
      //TODO: Da collaudare seguente navigate
      navigate("/");

  }


};

const login = (dispatch) => async (loginForm, navigate, server1, configUser, getNationsLists) => {  
    let uri = configUser.server;

    if ( uri  === undefined){
      uri = 'incontra/api/v2';
    }
    const response = await BaseService.post(`${uri}/login`, {
      jwtusername: loginForm.username,
      jwtpassword: loginForm.password
    });

    localStorage.setItem("user",loginForm.username)
    if(response.data){
      const user = response.data;
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('UserDescrizione', response.data.UserDescrizione)
      if(response.data.LuogoRitiro !== undefined){
        localStorage.setItem('luogoRitiro', JSON.stringify(response.data.LuogoRitiro))
      }else{
        localStorage.setItem('luogoRitiro', JSON.stringify([]))
      }
      if(response.data.configurazione !== undefined){
        localStorage.setItem('configurazione', JSON.stringify(response.data.configurazione))
        let findRoute = response.data.configurazione.find((res, i)=> res.visible)
        navigate("/"+findRoute.name)
      }else{
        localStorage.setItem('configurazione', JSON.stringify([]))
        navigate("/")
      }
      dispatch({ type: END_LOGIN, payload: { user, configAll: configUser} });
    }
    // else {
    //   if(server2 !== undefined){
    //     response2 = await BaseService.post(`${server2}/login`, {
    //       jwtusername: loginForm.username,
    //       jwtpassword: loginForm.password
    //     });
    // }
    // else{
    //   sendNotification(
    //     <NotificationContent service={"Errore!"} />,
    //     "Credenziali non valide",
    //     "error",
    //     10
    //   );
    //   return;
    // }
    // }

    // if(response2.data){
    //   const user = response2.data;
    //   localStorage.setItem('token', response2.data.token)
    //   localStorage.setItem('UserDescrizione', response2.data.UserDescrizione)
    //   if(response2.data.LuogoRitiro !== undefined){
    //     localStorage.setItem('luogoRitiro', JSON.stringify(response2.data.LuogoRitiro))
    //   }else{
    //     localStorage.setItem('luogoRitiro', JSON.stringify([]))
    //   }
    //   if(response2.data.configurazione !== undefined){
    //     localStorage.setItem('configurazione', JSON.stringify(response2.data.configurazione))
    //     let findRoute = response2.data.configurazione.find((res, i)=> res.visible)
    //     navigate("/"+findRoute.name)
    //   }else{
    //     localStorage.setItem('configurazione', JSON.stringify([]))
    //     navigate("/")
    //   }
    //   dispatch({ type: END_LOGIN, payload: { user, configAll: configUser} });
    // }
    // else {
    //   sendNotification(
    //     <NotificationContent service={"Errore!"} />,
    //     "Credenziali non valide",
    //     "error",
    //     10
    //   );
    // }

};

const logout = (dispatch) => async (navigate) => {
  navigate("/")
  localStorage.removeItem('token')
  localStorage.removeItem('UserDescrizione')
  localStorage.removeItem('configurazione')
  dispatch({ type: LOGOUT });
};

const addValidCheck = (dispatch) => async (param) => {
  dispatch({
    type: IS_VALID_ADD,
    payload: param,
  });
};

export const { Provider, Context } = createDataContext(
  authReducer,
  {
    login,
    logout,
    addValidCheck,
    loginforredirect
  }, // actions
  INITIAL_STATE // initial state
);
